<template>
    <div>
      <!-- Modal -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
         <h1>Data lake</h1><br><br>
         <br><h4>Cargar archivo</h4><br>
          <div class="form-group">
            <input  type="file" ref="fileInput" />
          </div>
          <div class="button-group">
            <button class="mx-1 btn px-4 py-2 btn-primary" @click="uploadFile">Subir archivo</button>
            <button class="mx-1 btn px-4 py-2 btn-secondary" @click="closeModal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showModal: false,
      };
    },
    mounted() {
      this.openModal();
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
        this.$router.push('/dashboard'); // Redirige al usuario al dashboard (reemplaza "/dashboard" con tu ruta correspondiente)
      },
      uploadFile() {
        const file = this.$refs.fileInput.files[0];
        // Aquí puedes realizar las operaciones de carga del archivo, por ejemplo, enviarlo a un servidor
        console.log(file);
      },
    },
  };
  </script>
  <style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    width: 400px; /* Ajusta el ancho del modal según tus necesidades */
    height: 300px; /* Ajusta la altura del modal según tus necesidades */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .button-group button {
    margin-right: 10px;
  }
  </style>
  